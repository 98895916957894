import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import OfferingPage from '../components/OfferingPage'

const OfferingTemplate = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="온라인 헌금 | 죠이플 교회"
        description="Available payment methods for offerings"
      />
      <OfferingPage />
    </Layout>
  )
}

export default OfferingTemplate
