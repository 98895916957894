import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from '@emotion/styled'
import BackgroundImage from 'gatsby-background-image'

const StyledFullScreenWrapper = styled.div`
  display: flex;
  width: 100%;
  min-height: 100%;
  flex-direction: column;
  overflow: hidden;
`
const BackgroundSection = ({ className, children }) => {
  const { offeringBackgroundImage } = useStaticQuery(
    graphql`
      query {
        offeringBackgroundImage: file(relativePath: { eq: "offering_page.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 3200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )
  const imageData = offeringBackgroundImage.childImageSharp.fluid
  return (
  <StyledFullScreenWrapper>
    <BackgroundImage Tag="section" className={className} fluid={imageData}>
      {children}
    </BackgroundImage>
  </StyledFullScreenWrapper>
)
}

const OfferingPageBackground = styled(BackgroundSection)`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  flex: 1;
`
export default OfferingPageBackground
