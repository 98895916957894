import React from 'react'
import styled from '@emotion/styled'
import BackgroundImage from 'gatsby-background-image'
import { graphql, useStaticQuery } from 'gatsby'
import OfferingPageBackground from './OfferingPageBackground'

const PageContainer = styled.section`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  padding: 67px 0 74px;
  background-image: url('../../static/images/prayer.svg');
  @media screen and (max-width: ${props => props.theme.responsive.large}) {
    padding: 49px 0 33px;
  }
`
const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100px;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: ${props => props.theme.responsive.large}) {
    width: auto;
    height: auto;
    margin: 50px 35px 40px 35px;
  }
`
const TitleHeader = styled.h1`
  color: ${props => props.theme.colors.text};
  font-family: "Apple SD Gothic Neo";
  align-items: center;
  font-size: 44px;
  text-align: center;
  font-weight: bold;
  letter-spacing: 0;
`
const TitleDescription = styled.h2`
  color: ${props => props.theme.colors.text};
  font-family: "Apple SD Gothic Neo";
  align-items: center;
  text-align: center;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
  margin: 25px 0 0 0;
`
const CardContainer = styled.section`
  width: 980px;

  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  justify-content: center;
  margin: 45px 0 0;

  @media screen and (max-width: 1200px) {
    width: auto;
  }
`
const CardItemContainer = styled.div`
  width: 450px;
  display: flex;
  box-sizing: border-box;
  border: 1px solid #43B649;
  background-color: #FFFFFF;
  align-items: center;
  flex-direction: column;
  margin-bottom: 30px;
  opacity: 0.8;
  margin: 0 15px 30px;
  @media screen and (max-width: ${props => props.theme.responsive.small}) {
    width: auto;
    margin: 0 12px 30px;
  }
`
const CardItemHeader = styled.h1`
  color: ${props => props.theme.colors.text};
  font-family: "Apple SD Gothic Neo";
  font-size: 30px;
  font-weight: 500;
  letter-spacing: 0;
  margin: 55px 0 0 0;
  @media screen and (max-width: ${props => props.theme.responsive.small}) {
    font-size: 26px;
  }
`
const CardItemDescription = styled.div`
  color: ${props => props.theme.colors.text};
  font-family: "Apple SD Gothic Neo";
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  margin: 20px 0 0 0;
  line-height: 22px;
  text-align: center;
  @media screen and (max-width: ${props => props.theme.responsive.small}) {
    font-size: 11px;
  }
`
const CardItemDivider = styled.div`
  box-sizing: border-box;
  height: 1px;
  width: 52px;
  border: 1px solid #2F3133;
  margin: ${props => (props.primary ? '40px 0 0 0' : '30px 0 0 0')};
`
const CardItemDetailedDescription = styled.div`
  color: ${props => props.theme.colors.text};
  font-family: "Apple SD Gothic Neo";
  font-size: 18px;
  font-weight: ${props => props.textBold || '400'};
  letter-spacing: 0;
  margin: 30px 17px 30px 17px;
  line-height: ${props => props.lineHeight || '24px'};
  @media screen and (max-width: ${props => props.theme.responsive.large}) {
    width: 385px;
  }
  @media screen and (max-width: ${props => props.theme.responsive.small}) {
    font-size: 14px;
    width: 270px;
  }
`
const CardItemDetailedDescriptionIndent = styled.div`
  display: inline;
  width: 100px;
  height: 100px;
  margin: ${props => (props.firstIndent || '0 0 0 6.6em')};
  @media screen and (max-width: ${props => props.theme.responsive.small}) {
    margin: ${props => (props.firstIndent || '0 0 0 6.6em')};
  }
`
const CardItemDetailedDescriptionIndentSecond = styled(CardItemDetailedDescriptionIndent)`
  margin: ${props => (props.firstIndent || '0 0 0 6.6em')};
`
const CardItemDetailedDescriptionBottm = styled(CardItemDetailedDescription)`
  text-align: center;
  margin: 35px 15px 35px 15px;
`
const DonateLink = styled.a`
  color: #F26921;
  font-family: "Apple SD Gothic Neo";
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  margin: -20px 0 30px 0;
  text-decoration: none;
`
const greenText = { color: '#43B649'}
const lineHeight = { lineHeight: '40px'}
const textBold = { fontWeight: '800'}
const firstIndent = { margin: '0 0 0 1.3em'}

const OfferingPage = () => {
  return (
    <OfferingPageBackground>
    <PageContainer>
      <TitleContainer>
        <TitleHeader>
          온라인 헌금 방법
        </TitleHeader>
        <TitleDescription>
          {`** 온라인 헌금 기능은 안전하며 온라인 헌금에 필요한 어떠한 개인 정보 (이름, 카드번호, 주소, 연락처 등)도 교회 홈페이지에 저장이 되지 않습니다. **`}
        </TitleDescription>
      </TitleContainer>

      <CardContainer>
        <CardItemContainer>
          <CardItemHeader>
            <span style={greenText}>Zelle</span>로 헌금하기
          </CardItemHeader>
          <CardItemDescription>
            {`Zelle을 이용하시기를 권고 합니다. (수수료 X)`}
          </CardItemDescription>
          <CardItemDivider />
            <CardItemDetailedDescription>
            {`1.`} &ensp;{`은행 app 혹은 웹 사이트 들어가신 후 Zelle을`}<br /> <CardItemDetailedDescriptionIndent style={firstIndent}>{`선택해주세요.`}</CardItemDetailedDescriptionIndent><br /><span style={lineHeight} />
            {`2.`} &ensp;{`Send Money를 선택해주세요.`}<span style={lineHeight}><br /></span>
            {`3.`} &ensp;<span style={textBold}>{`joyfulchurchfinance@gmail.com`}</span>{`로`}<br />
            <CardItemDetailedDescriptionIndent style={firstIndent}>{` 보내주세요.`}</CardItemDetailedDescriptionIndent><br /><span style={lineHeight} />
            {`4.`} &ensp;{`Send를 눌러주세요.`}
            </CardItemDetailedDescription>
        </CardItemContainer>

        <CardItemContainer>
          <CardItemHeader>
            <span style={greenText}>은행계좌 이체</span>로 헌금하기
          </CardItemHeader>
          <CardItemDescription>
            계좌 이체로 헌금하시기를 희망하시는 분들은 아래 정보를<br /> 참고하여 주십시오.
          </CardItemDescription>
          <CardItemDivider />
          <CardItemDetailedDescription>
            <span style={textBold}>Bank Name:</span> <span style={lineHeight}>Chase Bank</span><br />
            <span style={textBold}>Bank Address:</span> 3104 Crow Canyon Pl.,<br />

            <CardItemDetailedDescriptionIndent> San Ramon, CA 94583</CardItemDetailedDescriptionIndent><br/><span style={lineHeight} />

            <span style={textBold}>Routing Number:</span> 322271627<br /><span style={lineHeight} />
            <span style={textBold}>Checking Account Number:</span> 608520331<br />
          </CardItemDetailedDescription>
        </CardItemContainer>

        <CardItemContainer>
          <CardItemHeader>
            <span style={greenText}>Paypal</span>로 헌금하기
          </CardItemHeader>
          <CardItemDescription>
            Paypal 이용시 3%정도의 수수료가 공제됩니다.<br /> (ex. $300 헌금 –> Paypal 사용시 $291 입금)
          </CardItemDescription>
          <CardItemDivider />
          <CardItemDetailedDescriptionBottm>
            <span style={textBold}>Recipient:</span> joyfulchurchfinance@gmail.com
          </CardItemDetailedDescriptionBottm>
          <DonateLink href="//www.paypal.com" target="_blank">
            헌금하기 >
          </DonateLink>
        </CardItemContainer>

        <CardItemContainer>
          <CardItemHeader>
            <span style={greenText}>우편</span>으로 헌금하기
          </CardItemHeader>
          <CardItemDescription>
            우편으로 헌금하시기를 희망하시는 분들은 아래 주소로<br /> check을 보내주십시오.
          </CardItemDescription>
          <CardItemDivider />
          <CardItemDetailedDescriptionBottm>
            <span style={textBold}>Joyful Church<br/> </span>
            5678 Bellevue Cir.<br/>
            Dublin, CA 94568
          </CardItemDetailedDescriptionBottm>
        </CardItemContainer>
      </CardContainer>
    </PageContainer>
    </OfferingPageBackground>
  )
}

export default OfferingPage
